import React from 'react';

import {BasicCardVariants} from '../../../utils/_enums/basic-card-variants.enum';
import {MediaContentType} from '../../../utils/_enums/image-content-type.enum';
import {ProductCategories} from '../../../utils/_enums/product-categories.enum';
import TeaserCardCategory from '../teaser-card-components/teaser-card-category/teaser-card-category';
import TeaserCardImages from '../teaser-card-components/teaser-card-images/teaser-card-images';
import styles from './basic-card.module.scss';

export interface BasicCardProps {
    id?: string;
    media: {
        mediaUrl: string;
        contentType: MediaContentType;
        profilePictureUrl?: number;
        initialLoad?: boolean;
        posterUrl?: string;
        isTeaser?: boolean;
    };
    category?: ProductCategories;
    type: BasicCardVariants;
    author?: string;
    duration?: string;
    children: React.ReactNode;
    redirectUrl?: string;
    onClick?: () => void;
    selected?: boolean;
    disabled?: boolean;
    inverted?: boolean;
}

const BasicCard = (props: BasicCardProps): JSX.Element => {

    return (
        <div
            id={props?.id}
            className={`
            ${styles['basic-card-wrapper']}
            ${styles[`${props.type ? props.type : ''}`]}
            ${styles[`${!props.disabled && props.onClick ? 'clickable' : ''}`]}
            ${styles[`${props.disabled ? 'disabled' : ''}`]}
            `}
            onClick={!props.disabled ? props.onClick : undefined}
            data-testid="basic-card"
        >
            <div
                className={`${styles['basic-card-content-container']}
                ${styles[props.selected ? 'selected' : '']}
                ${props.inverted ? styles['inverted'] : ''}`}
            >
                {/* Cover art image and small round picture of the originator */}
                <div className={styles['teaser-card-images']}>
                    <TeaserCardImages
                        {...props.media}
                        variant={props.type}
                        duration={props.duration}
                        redirectUrl={props.redirectUrl}
                    />

                    {/* Optional text under the big image - right aligned */}
                    {props.author && (
                        <div className={styles['author']}>{props.author}</div>
                    )}

                    {/* Indicator (top right corner) of product category => Show only in mobile version of slider */}
                    {props.category &&
                        props.type ===
                            BasicCardVariants.BASIC_CARD_HORIZONTAL_SMALL && (
                            <div
                                className={
                                    styles['teaser-card-category-mobile']
                                }
                            >
                                <TeaserCardCategory
                                    category={props.category}
                                    style={'kinked'}
                                />
                            </div>
                        )}

                    {props.category &&
                        props.type === BasicCardVariants.PODCAST_TEASER && (
                            <div className={styles['podcast-category-wrapper']}>
                                <TeaserCardCategory
                                    category={props.category}
                                    style={'arrow'}
                                />
                            </div>
                        )}
                </div>

                {/* Indicator (top right corner) of product category */}
                {props.category && (
                    <div className={styles['teaser-card-category']}>
                        <TeaserCardCategory
                            category={props.category}
                            style={'kinked'}
                        />
                    </div>
                )}

                {/* InstanceCard or VariantCard components */}
                {props.children}
            </div>
        </div>
    );
};

export default BasicCard;
