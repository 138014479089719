import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { ProductCategories } from '../../../../utils/_enums/product-categories.enum';
import styles from './teaser-card-category.module.scss';

interface TeaserCardCategoryProps {
    style: 'arrow' | 'kinked';
    category: ProductCategories;
}

const TeaserCardCategory = (props: TeaserCardCategoryProps): JSX.Element => {

    const getCategoryIcon = (): JSX.Element | undefined => {
        switch (props.category) {
            case ProductCategories.EBOOK:
                return <FontAwesomeIcon icon={['far', 'book-open']} />;
            case ProductCategories.ART:
                return <FontAwesomeIcon icon={['far', 'palette']} />;
            case ProductCategories.AUDIO:
                return <FontAwesomeIcon icon={['far', 'headphones']} />;
            case ProductCategories.BOOK:
                return <FontAwesomeIcon icon={['far', 'book']} />;
        }
    }

    return (
        <div className={`${styles['category-image-wrapper']}
        ${styles[`category-image-wrapper-${props.category}`]}
        ${styles[`category-image-wrapper-${props.style}`]}`}>
            {getCategoryIcon()}
        </div>
    );
};

export default TeaserCardCategory;
