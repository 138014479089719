import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';

import {useCardText} from '../../../../api/hooks/useCardText.hook';
import {InstanceStatus} from '../../../../api/models/instance-status';
import {BasicCardVariants} from '../../../../utils/_enums/basic-card-variants.enum';
import {ButtonVariantsEnum} from '../../../../utils/_enums/button-variants.enum';
import {SalesType} from '../../../../utils/_enums/sales-type';
import {TypographyVariantsEnum} from '../../../../utils/_enums/typeography-variants.enum';
import {getLocaleEthPrice, getLocalePrice} from '../../../../utils/_helper/locale-helper';
import {formatDate} from '../../../../utils/_helper/time-helper';
import {Button} from '../../button/button';
import {Link} from '../../link/link';
import {LinkBase} from '../../link/link-base/link-base';
import {Typography} from '../../typography/typography';
import styles from './teaser-card-footer.module.scss';

export interface TeaserCardFooterProps {
    purchasable?: {
        price?: number;
        ethPrice: number;
        ownerId?: number;
        status: InstanceStatus;
        onBuy?: () => void;
        onBid?: () => void;
        buttonText?: string;
        buyButtonDisabled?: boolean;
        buyButtonPending?: boolean;
    };
    salesFutureDate?: string;
    type?: BasicCardVariants;
    salesType?: string;
    additionalInfo?: string;
    date?: string;
    redirectUrl?: string;
    moreInfoLinkText?: string;
    onMoreInfo?: () => void;
    onMoreInfoAndBuy?: () => void;
    onInformation?: () => void;
    isURLExternal?: boolean;
    onDetailsAndActions?: () => void;
    inverted?: boolean;
}

const TeaserCardFooter = (props: TeaserCardFooterProps): JSX.Element => {
    const data = useCardText();

    return (
        <div
            className={`${styles['footer-wrapper']} ${styles[`${props.type ? props.type : ''}`]
            }`}
        >
            {props.additionalInfo && (
                <div className={styles['additional-info-wrapper']}>
                    <Typography
                        disableMarkdown={true}
                        variant={TypographyVariantsEnum.TOPLINE_OR_LABEL}
                    >
                        {props.additionalInfo}
                    </Typography>
                </div>
            )}

            {props.purchasable && (
                <div
                    className={`${styles['buy-wrapper']}
                ${props.salesType === SalesType.FIXED_PRICE
                        ? 'buy-wrapper-fixed-price'
                        : styles['buy-wrapper-auction']
                    }`}
                >
                    <div className={styles['price-wrapper']}>
                        <div
                            className={
                                props.purchasable.ownerId &&
                                props.salesType === SalesType.FIXED_PRICE
                                    ? styles['secondary-price']
                                    : styles['primary-price']
                            }
                        >
                            {
                                props.purchasable.price !== undefined
                                    ? <Typography
                                        disableMarkdown={true}
                                        className={`${styles['price']} ${props.inverted ? styles['inverted'] : ''}`}
                                        variant={
                                            props.purchasable.ownerId &&
                                            props.salesType === SalesType.FIXED_PRICE
                                                ? TypographyVariantsEnum.TOPLINE_OR_LABEL
                                                : TypographyVariantsEnum.HEADING6
                                        }
                                    >
                                        {`${getLocalePrice(props.purchasable.price)}€`}
                                    </Typography>
                                    : undefined
                            }
                        </div>
                        <div
                            className={
                                props.purchasable.ownerId &&
                                props.salesType === SalesType.FIXED_PRICE
                                    ? styles['primary-price']
                                    : styles['secondary-price']
                            }
                        >
                            <Typography
                                disableMarkdown={true}
                                className={`${styles['price']} ${props.inverted ? styles['inverted'] : ''}`}
                                variant={TypographyVariantsEnum.HEADING6}
                            >
                                {getLocaleEthPrice(props.purchasable.ethPrice) +
                                    'Ξ'}
                            </Typography>
                        </div>
                    </div>

                    {
                        props.purchasable.onBuy
                            ? <Button
                                onClick={props.purchasable.onBuy}
                                disabled={
                                    props.purchasable.status !== InstanceStatus.OPEN
                                    || props.purchasable.buyButtonDisabled
                                }
                                pending={props.purchasable.buyButtonPending}
                                variant={ButtonVariantsEnum.PRIMARY}
                                inverted={props.inverted}
                                icon={{
                                    element: (
                                        <FontAwesomeIcon
                                            icon={['far', 'cart-plus']}
                                        />
                                    ),
                                    align: 'right'
                                }}
                            >
                                {props.purchasable.buttonText}
                            </Button>
                            : undefined
                    }
                    {
                        props.purchasable.onBid
                            ? <Button
                                onClick={props.purchasable.onBid}
                                variant={ButtonVariantsEnum.PRIMARY}
                            >
                                {data?.bid}
                            </Button>
                            : undefined
                    }
                </div>
            )}

            {props.onMoreInfoAndBuy && (
                <LinkBase href={props.redirectUrl ?? ''}>
                    <div className={styles['info-and-buy-button-wrapper']}>
                        <Button
                            inverted={props.inverted}
                            variant={ButtonVariantsEnum.SECONDARY_OUTLINED}
                            onClick={props.onMoreInfoAndBuy}
                        >
                            {data?.informAndBuy}
                        </Button>
                    </div>
                </LinkBase>
            )}

            {props.salesFutureDate && (
                <div className={`${styles['sales-future-date']} ${props.inverted ? styles['inverted'] : ''}`}>
                    <span>
                        <FontAwesomeIcon icon={['far', 'clock']}/>
                    </span>
                    {props.salesFutureDate}
                </div>
            )}

            {props.onInformation && (
                <LinkBase href={props.redirectUrl ?? ''}>
                    <div className={styles['info-and-buy-button-wrapper']}>
                        <Button
                            variant={ButtonVariantsEnum.SECONDARY_OUTLINED}
                            inverted={props.inverted}
                            onClick={props.onInformation}
                        >
                            {data?.inform}
                        </Button>
                    </div>
                </LinkBase>
            )}

            {props.onDetailsAndActions && (
                <LinkBase href={props.redirectUrl ?? ''}>
                    <div className={styles['details-and-auction-button-wrapper']}>
                        <Button
                            variant={ButtonVariantsEnum.SECONDARY_OUTLINED}
                            onClick={props.onDetailsAndActions}
                        >
                            {data?.detailsAndActions}
                        </Button>
                    </div>
                </LinkBase>
            )}

            {props.onMoreInfo && (
                <div className={styles['more-info-wrapper']}>
                    {props.date && (
                        <div className={styles['date-wrapper']}>
                            {formatDate(props.date)}
                        </div>
                    )}
                    <Link onClick={props.onMoreInfo} href={props.redirectUrl ?? ''} external={props.isURLExternal}>
                        {props.moreInfoLinkText
                            ? props.moreInfoLinkText
                            : ''}
                    </Link>
                </div>
            )}
        </div>
    );
};

export default TeaserCardFooter;
