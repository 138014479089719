import React from 'react';

import {buildProfilePictureUrl} from '../../../../utils/_helper/build-profile-picture-url';
import { Media } from '../../media/media';
import styles from './teaser-card-profile-picture.module.scss';

interface TeaserCardProfilePictureProps {
    profilePictureId: number;
    initialLoad?: boolean;
}

const TeaserCardProfilePicture = (props: TeaserCardProfilePictureProps): JSX.Element => {
    return (
        <Media
            className={styles['profile-picture']}
            alt={'profile-picture'}
            isProfile={true}
            initialLoad={props.initialLoad}
            src={buildProfilePictureUrl(props.profilePictureId)}
            classNameWrapper={styles['profile-picture-wrapper']}
            squared
        />
    );
};

export default TeaserCardProfilePicture;
