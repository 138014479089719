import {faMicrophone} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';

import {AspectRatio} from '../../../../utils/_enums/aspect-ratio.enum';
import {BasicCardVariants} from '../../../../utils/_enums/basic-card-variants.enum';
import {ButtonVariantsEnum} from '../../../../utils/_enums/button-variants.enum';
import {MediaContentType} from '../../../../utils/_enums/image-content-type.enum';
import {Button} from '../../button/button';
import { Media } from '../../media/media';
import TeaserCardProfilePicture from '../teaser-card-profile-picture/teaser-card-profile-picture';
import styles from './teaser-card-images.module.scss';

export interface TeaserCardImagesProps {
    mediaUrl: string;
    contentType: MediaContentType;
    profilePictureUrl?: number;
    label?: string;
    aspectRatio?: AspectRatio;
    duration?: string;
    variant: BasicCardVariants;
    redirectUrl?: string;
    initialLoad?: boolean;
    posterUrl?: string;
    isTeaser?: boolean;
}

const TeaserCardImages = (props: TeaserCardImagesProps): JSX.Element => {

    return (
        <div
            className={`${styles['basic-card-image-wrapper']}
            ${props.aspectRatio
                ? styles[`basic-card-image-wrapper-${props.aspectRatio}`]
                : ''
            }
            ${props.variant ? styles[`${props.variant}`] : ''}`}
        >
            <div>
                <Media
                    src={props.mediaUrl}
                    className={styles['image']}
                    objectFit={'contain'}
                    contentType={props.contentType}
                    redirectUrl={props.redirectUrl}
                    initialLoad={props.initialLoad}
                    posterUrl={props.posterUrl}
                    isTeaser={props.isTeaser}
                    squared
                />
            </div>

            {props.duration &&
            props.variant === BasicCardVariants.PODCAST_TEASER && (
                <div className={styles['podcast-duration']}>
                    <Button
                        variant={ButtonVariantsEnum.LEFT_ARROW}
                        className={styles['duration-button']}
                        icon={{
                            element: (
                                <FontAwesomeIcon icon={faMicrophone}/>
                            ),
                            align: 'left'
                        }}
                    >
                        {props.duration}
                    </Button>
                </div>
            )}
            {props.profilePictureUrl && (
                <div className={styles['profile-picture-wrapper']}>
                    <TeaserCardProfilePicture
                        initialLoad={props.initialLoad}
                        profilePictureId={props.profilePictureUrl}
                    />
                </div>
            )}

            {props.label && (
                <div className={styles['label-wrapper']}>
                    <div className={styles['label']}>
                        {props.label.toUpperCase()}
                    </div>
                </div>
            )}
        </div>
    );
};

export default TeaserCardImages;
